<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :customFiltersShow="true"
          :zoneFilterShow="zone_agency_filter"
          :agencyFilterShow="zone_agency_filter"
          :locationFilterShow="true",
          :dateRangeFilterShow="true",
          :locationDefaultValue="true",
          @changeFilter="updateFilter",
          @locations="updateLocations"
        )

  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          :color="colors.primary"
        )

  div(v-else)
    b-row(v-if="!meetings_total")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_daily_dialogues_found') }}

    div(v-else)
      b-row
        b-col(sm="3")
          statistic-card-vertical-index(:statistic="happiness_index")
        b-col(sm="3")
          statistic-card-vertical-happy(:statistic="happinessHappy")
        b-col(sm="3")
          statistic-card-vertical-neutral(:statistic="happinessNeutral")
        b-col(sm="3")
          statistic-card-vertical-sad(:statistic="happinessSad")

      b-row
        b-col(cols="12")
          bar-chart-happiness(
            v-if="happinessPerDay.labels && happinessPerDay.labels.length",
            :happiness-data="happinessPerDay"
          )

      b-card
        feather-icon-button(v-if="commentsVisible", icon="ArrowLeftIcon", variant="info", size="21", class="mb-1" @clicked="handleComments(false)")
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(v-if="!commentsVisible", head-variant="light")
            b-tr
              th {{ $t('happiness.date') }}
              th {{ $t('happiness.start_time') }}
              th {{ $t('happiness.end_time') }}
              th {{ $t('happiness.created_by') }}
              th {{ $t('happiness.location') }}
              th {{ $t('happiness.happy') }}
              th {{ $t('happiness.neutral') }}
              th {{ $t('happiness.sad') }}
              th {{ $t('happiness.mood') }}
              th {{ $t('happiness.image') }}
              th {{ $t('happiness.action') }}
            b-tr(v-for="(meeting, index) in meetings", :key="index")
              b-td {{ meeting.dateString }}
              b-td {{ meeting.start_time }}
              b-td {{ meeting.end_time }}
              b-td {{ meeting.created_by }}
              b-td {{ meeting.location }}
              b-td {{ meeting.happy }}
              b-td {{ meeting.neutral }}
              b-td {{ meeting.sad }}
              b-td {{ meeting.happiness_index_text() }}
              b-td(v-if="meeting.imageKey")
                b-button(
                    size="sm",
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                    type="button",
                    variant="primary",
                    @click="openImage(meeting.imageKey)"
                    style="width: 5.5rem"
                  )
                    | {{ $t('message.open') }}
              b-td(v-else)
                b-button(
                  size="sm",
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                  type="button",
                  variant="info",
                  v-b-modal="'uploadImg' + index"
                  @click="openModal"
                  style="width: 5.5rem"
                )
                  | {{ $t('message.upload') }}
                b-modal(
                  :id="'uploadImg' + index"
                  centered
                  no-close-on-backdrop
                  modal-class="modal-info"
                  ok-variant="info"
                  cancel-variant="outline-secondary"
                  :title="$t('message.confirm_action')"
                  ok-title="OK"
                  :cancel-title="$t('message.cancel')"
                  :ok-disabled="!imgData.fileInfo"
                  @ok="saveImgInAWS(meeting.id)"
                  @hide="hideAction()"
                )
                  b-td(class="d-flex flex-column")
                    small(v-if="imgData.fileInfo") {{ $t('message.nameImg') }}: {{ imgData.fileInfo.name }}
                    div(class="align-self-start mt-2")
                      b-button(
                        @click="uploadImage()"
                        variant="light"
                        class=""
                      ) {{ imgData.fileInfo ? $t('message.modify') : $t('Upload') }}
              b-td
                delete-button(v-if="userRole === 'consultant'", v-b-modal="`modal ${index}`")
                b-modal(
                  v-if="userRole === 'consultant'"
                  :id="`modal ${index}`"
                  centered
                  no-close-on-backdrop
                  modal-class="modal-danger"
                  ok-variant="danger"
                  cancel-variant="outline-secondary"
                  :title="$t('message.confirm_action')"
                  :ok-title="$t('message.delete')"
                  :cancel-title="$t('message.cancel')"
                  @ok="deleteMeeting(meeting.id, meeting.improvements, meeting.recognitions)"
                )
                  | {{ $t('message.confirm_delete_daily_dialogue') }}
                feather-icon-button(
                  v-if="meeting.employeeSurveys && meeting.employeeSurveys.length",
                  icon="MessageSquareIcon",
                  variant="info",
                  @clicked="handleComments(true, meeting.employeeSurveys)"
                )
            image-compressor(
              style="display: none",
              :done="getImgCompressed",
              ref="compressor"
            )
          b-thead(v-else, head-variant="light")
            b-tr
              th {{ $t('happiness.date') }}
              th {{ $t('happiness.location') }}
              th {{ $t('message.tableHeader.state') }}
              th {{ $t('message.tableHeader.comments') }}
            b-tr(v-for="(survey, index) in employeeSurveysSelected", :key="index")
              b-td {{ survey.date }}
              b-td {{ survey.location }}
              b-td(:class="stateVariants[survey.answer]") {{ $t(`happiness.${survey.answer}`) }}
              b-td(:class="stateVariants[survey.answer]") {{ survey.comment }}

      b-button(class="mr-4 mb-1", :disabled="!meetings.length")
        json-excel(:data="meetings", :fields="excelFieldsDialogues")
          | {{ $t('download_xlsx_daily_dialogues') }}

      b-button(class="mr-4 mb-1", :disabled="!totalEmployeeSurveys.length")
        json-excel(:data="totalEmployeeSurveys", :fields="excelFieldsComments")
          | {{ $t('download_xlsx_comments') }}
</template>
  
<script>
import { onMounted, ref } from "@vue/composition-api/dist/vue-composition-api"
import store from "@/store"
import useNotifications from "@/composables/useNotifications"
import { BTable, BTableSimple, BThead, BTr, BTd, BCard, BModal, VBModal } from "bootstrap-vue"
import JsonExcel from "vue-json-excel"
import StatisticCardVerticalHappy from './charts/StatisticCardVerticalHappy.vue'
import StatisticCardVerticalNeutral from './charts/StatisticCardVerticalNeutral.vue'
import StatisticCardVerticalSad from './charts/StatisticCardVerticalSad.vue'
import StatisticCardVerticalIndex from './charts/StatisticCardVerticalIndex.vue'
import BarChartHappiness from './charts/ChartjsBarChartHappiness.vue'
import Filters from "@/views/organization/Filters.vue";
import i18n from '@/libs/i18n'
import Ripple from 'vue-ripple-directive'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton'
import FeatherIconButton from '@/views/components/Shared/Buttons/FeatherIconButton'
import useCommon from '@/views/organization/useCommon'
import ImageCompressor from '@/views/habit/ImageCompressor.vue'
import awsConnection from '@/views/habit/aws'
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    BModal,
    Filters,
    JsonExcel,
    StatisticCardVerticalHappy,
    StatisticCardVerticalNeutral,
    StatisticCardVerticalSad,
    StatisticCardVerticalIndex,
    BarChartHappiness,
    Loading,
    DeleteButton,
    FeatherIconButton,
    ImageCompressor,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  setup() {
    const userData = store.state?.userStore?.userData;
    const userRole = userData.role;
    const client_id = userRole !== "admin" ? userData.client.$oid : null;
    const userLocations = userData.locations?.length ? userData.locations : null;
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { handleError, openImage } = useCommon()
    const { getDatesFromRange, happinessIndex, updateDailyDialogueWithKey } = useCommonDashboards()
    const { getItemsWithAggregate, ObjectId, updateItem, updateItems } = realmConnection()
    const meetings = ref([]);
    const meetings_total = ref(0);
    const happiness_index = ref(0)
    const happinessPerDay = ref({})
    const happinessHappy = ref(0)
    const happinessNeutral = ref(0)
    const happinessSad = ref(0)
    // const compliance = ref(0)
    const locations = localStorage.locations ? ref(JSON.parse(localStorage.locations)) : ref([])
    const locationFilter = ref(userLocations ? userLocations.map(e => e.value) : []);
    const zoneFilter = ref([]);
    const agencyFilter = ref([]);
    const locationDialogues = ref({})
    const locationHappiness = ref({})
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
    const isLoading = ref(true)
    const { zone_agency_filter, default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const commentsVisible = ref(false)
    const employeeSurveysSelected = ref([])
    const totalEmployeeSurveys = ref([])
    const stateVariants = { happy: 'text-success', neutral: 'text-warning', sad: 'text-danger' }
    const excelFieldsDialogues = ref({
      [i18n.t('happiness.date')]: "dateString",
      [i18n.t('happiness.start_time')]: "start_time",
      [i18n.t('happiness.end_time')]: "end_time",
      [i18n.t('happiness.created_by')]: "created_by",
      [i18n.t('happiness.location')]: "location",
      [i18n.t('happiness.happy')]: "happy",
      [i18n.t('happiness.neutral')]: "neutral",
      [i18n.t('happiness.sad')]: "sad",
      [i18n.t('happiness.mood')]: "happiness_index",
      [i18n.t('happiness.id')]: "id",
    })
    const excelFieldsComments = ref({
      [i18n.t('happiness.date')]: "date",
      [i18n.t('happiness.location')]: "location",
      [i18n.t('message.tableHeader.state')]: "answer",
      [i18n.t('message.tableHeader.comments')]: "comment",
    })

    // To upload image to AWS
    const compressor = ref(null)
    const imgData = ref({})
    const { singleUpload } = awsConnection()

    async function listMeetings() {
      isLoading.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      try {
        const initialQuery = {
          client_id: ObjectId(client_id),
          deleted: { $ne: true },
          date: { $gte: startFilter, $lt: endFilter },
        }

        const finalQuery = {}

        if (locationFilter.value.length > 0) {
          finalQuery['location._id'] = { $in: locationFilter.value }
        }

        if (zoneFilter.value.length > 0) {
          finalQuery['location.zone'] = { $in: zoneFilter.value }
        }

        if (agencyFilter.value.length > 0) {
          finalQuery['location.agency'] = { $in: agencyFilter.value }
        }

        const pipeline = [
          { $match: initialQuery },
          {
            $lookup: {
              from: 'location',
              localField: 'location',
              foreignField: '_id',
              pipeline: [ { $project: { location: 1, zone: 1, agency: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'location'
            }
          },
          { $match: finalQuery },
          {
            $lookup: {
              from: 'worker',
              localField: 'created_by',
              foreignField: '_id',
              pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'created_by'
            }
          },
          {
            $lookup: {
              from: 'employee_survey',
              localField: 'employeeSurveys',
              foreignField: '_id',
              pipeline: [
                { $project: { answer: 1, comment: 1, createdAt: 1 , location: 1 } },
                {
                  $lookup: {
                    from: 'location',
                    localField: 'location',
                    foreignField: '_id',
                    pipeline: [ { $project: { location: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                    as: 'location',
                  },
                },
                { $addFields: { _id: { $toString: "$_id" }, location: { $arrayElemAt: ["$location", 0] } } }
              ],
              as: 'employeeSurveys'
            }
          },
          { $addFields: { _id: { $toString: "$_id" }, location: { $arrayElemAt: ["$location", 0] }, created_by: { $arrayElemAt: ["$created_by", 0] } } },
          { $sort: { date: 1 } },
        ]
      
        const items = await getItemsWithAggregate({ collection: 'meeting', pipeline })
        
        parseMeetingData(items, startFilter, endFilter)
      } catch (error) {
        console.log(error);
        handleError({ error, defaultMessage: i18n.t('message.err_daily_dialogues_list') })
      } finally {
        isLoading.value = false
      }
    }

    function parseMeetingData(items, startFilter, endFilter) {
      let dailyHappinessData = {}
      let dynamicDate = startFilter

      // Change the dates so that it matches the UTC time zone
      const timezoneOffsetHours = dynamicDate.getTimezoneOffset() / 60
      dynamicDate.setHours(dynamicDate.getHours() + timezoneOffsetHours)
      endFilter.setHours(endFilter.getHours() + timezoneOffsetHours)

      while (dynamicDate < endFilter) {
        const dateToAdd = dynamicDate.toLocaleDateString(`${default_language || 'en'}-US`)
        dailyHappinessData[dateToAdd] = { happy: 0, neutral: 0, sad: 0 }
        dynamicDate = new Date(dynamicDate.getFullYear(), dynamicDate.getMonth(), dynamicDate.getDate() + 1)
      }

      let meetings_data = []
      let happiness_data = { happy: 0, neutral: 0, sad: 0 }
      let locationData = {}
      let employeeSurveysData = []

      for (const meeting of items) {
        const date = meeting.date
        date.setHours(date.getHours() + timezoneOffsetHours)
        const dateString = date.toLocaleDateString(`${default_language || 'en'}-US`)
        const employeeSurveys = meeting.employeeSurveys?.filter(e => e.comment).map(e => {
          const employeeSurveyDate = new Date(e.createdAt)
          employeeSurveyDate.setHours(employeeSurveyDate.getHours() + timezoneOffsetHours)
          const employeeSurveyDateString = employeeSurveyDate.toLocaleDateString(`${default_language || 'en'}-US`)
          return {
            date: employeeSurveyDateString,
            location: e.location.location,
            answer: e.answer,
            comment: e.comment,
          }
        }) || []
        employeeSurveysData = employeeSurveysData.concat(employeeSurveys);
        meetings_data.push({
            date,
            dateString,
            start_time: meeting.start,
            end_time: meeting.end,
            created_by: meeting.created_by?.name,
            location: meeting.location?.location,
            happy: meeting.happy,
            neutral: meeting.neutral,
            sad: meeting.sad,
            happiness_index: happinessIndex(meeting.happy, meeting.neutral, meeting.sad),
            happiness_index_text: function() {
              if (!this.happy && !this.neutral && !this.sad) return "0%"
              return `${this.happiness_index}%`
            },
            imageKey: meeting.imageKey,
            improvements: meeting.improvements,
            recognitions: meeting.recognitions,
            id: meeting._id,
            employeeSurveys
        });
        happiness_data.happy += meeting.happy
        happiness_data.neutral += meeting.neutral
        happiness_data.sad += meeting.sad

        // Data for bar chart
        dailyHappinessData[dateString].happy += meeting.happy
        dailyHappinessData[dateString].neutral += meeting.neutral
        dailyHappinessData[dateString].sad += meeting.sad
      }

      // Global values
      meetings.value = meetings_data
      meetings_total.value = meetings_data.length
      happinessHappy.value = happiness_data.happy
      happinessNeutral.value = happiness_data.neutral
      happinessSad.value = happiness_data.sad
      happiness_index.value = (!happiness_data.happy && !happiness_data.neutral && !happiness_data.sad)
        ? "-"
        : `${happinessIndex(happiness_data.happy, happiness_data.neutral, happiness_data.sad)}%`
      totalEmployeeSurveys.value = employeeSurveysData

      // Bar chart
      let dailyHappinessLabels = Object.keys(dailyHappinessData)
      let dailyHappinessValues = []

      for (const date in dailyHappinessData){
        dailyHappinessValues.push([dailyHappinessData[date].happy, dailyHappinessData[date].neutral, dailyHappinessData[date].sad])
      }

      happinessPerDay.value = {
        labels: dailyHappinessLabels,
        data: dailyHappinessValues,
      };
    }
    
    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      dateRangeFilter.value = data.dateRangeFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;
      
      listMeetings()
      handleComments(false)
    }

    function updateLocations(data) {
      if (!locations.value.length) {
        locations.value = data;
        listMeetings();
      }
    }

    const handleComments = (showComments, employeeSurveys) => {
      employeeSurveysSelected.value = employeeSurveys || []
      commentsVisible.value = showComments
    }

    const deleteMeeting = async (id, improvements, recognitions) => {
      try {
        const query = { _id: ObjectId(id) }
        const action = { $set: { deleted: true } }

        await updateItem({ collection: 'meeting', query, action })

        listMeetings()
        showSuccessMessage(i18n.t('message.daily_dialogue_remove'))
        
        // Delete improvements associated with daily dialogue
        if (improvements?.length) {
          try {
            await updateItems({ collection: 'improvement', query: { _id: { $in: improvements } }, action })
          } catch (error) {
            console.log(error)
          }
        }

        // Delete recognitions associated with daily dialogue
        if (recognitions?.length) {
          try {
            await updateItems({ collection: 'recognition', query: { _id: { $in: recognitions } }, action })
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.daily_dialogue_remove_error'))
      }
    }
    
    const openModal = () => {
      uploadImage()
    }

    const uploadImage = () => {
      compressor.value.$el.click()
    }

    const getImgCompressed = (obj) => {
      imgData.value = {
        fileInfo: obj.compressed,
        destinationFolder: `${userData.client.$oid}/daily-dialogues`
      }
    }

    const hideAction = () => {
      imgData.value = {}
    }

    const saveImgInAWS = async (id) => {
      isLoading.value = true
      try {
       const key =  await singleUpload(imgData.value.fileInfo, imgData.value.destinationFolder)
       await updateDailyDialogueWithKey(id, key)
       listMeetings()
      } catch (err) {
        console.log(err)
      }
      isLoading.value = false
    }

    onMounted(() => {
      listMeetings()
    });

    return {
      meetings,
      meetings_total,
      happiness_index,
      // compliance,
      updateFilter,
      updateLocations,
      excelFieldsDialogues,
      excelFieldsComments,
      openImage,
      locationDialogues,
      locationHappiness,
      isLoading,
      zone_agency_filter,
      userRole,
      deleteMeeting,
      happinessPerDay,
      happinessHappy,
      happinessNeutral,
      happinessSad,
      commentsVisible,
      handleComments,
      employeeSurveysSelected,
      stateVariants,
      totalEmployeeSurveys,
      openModal,
      compressor,
      imgData,
      uploadImage,
      getImgCompressed,
      hideAction,
      saveImgInAWS,
      colors
    };
  },
};
</script>