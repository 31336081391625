<template>
  <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      :variant="`flat-${variant}`"
      class="btn-icon rounded-circle"
      @click="$emit('clicked')"
  >
    <feather-icon :icon="icon" :size="size"/>
  </b-button>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  name: 'FeatherIconButton',
  directives: {
    Ripple,
  },
  props: {
    icon: {
      type: String,
      default: "EyeIcon",
    },
    variant: {
      type: String,
      default: "warning",
    },
    size: {
      type: String,
      default: "14",
    },
  },
}
</script>

<style scoped>

</style>
